import React from "react";
import { telegramSupportLink } from "../App";

const SideBar = () => {
  return (
    <aside className="sidebar">
      <ul>
        <li>
          <a className="menu-item active" href="/">
            <img src="/iii/1st.svg" alt="1s" style={{ marginRight: "15px" }} />
            <span>Exchange </span>
          </a>
        </li>
        <li>
          <a className="menu-item" href="/">
            <img src="/iii/2st.svg" alt="1s" style={{ marginRight: "15px" }} />
            <span>Buy / Sell </span>
          </a>
        </li>

        <li>
          <a className="menu-item" href="https://top-exchange.pro/faq">
            <img src="/iii/4st.svg" alt="1s" style={{ marginRight: "15px" }} />
            <span>F.A.Q</span>
          </a>
        </li>
        <li>
          <a className="menu-item" href={telegramSupportLink}>
            <img src="/iii/5st.svg" alt="1s" style={{ marginRight: "15px" }} />
            <span>Online support</span>
          </a>
        </li>
      </ul>
    </aside>
  );
};
export default SideBar;
