import React from "react";
import ShopSection from "../components/homeComponents/shopSection";
import LastTransactions from "../components/homeComponents/lastTransactions";

const HomeScreen = ({ match }) => {
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <ShopSection />
      <LastTransactions />
    </React.Fragment>
  );
};

export default HomeScreen;
