import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/homeScreen";
import OrderScreen from "./screens/orderScreen";
import NotFound from "./screens/notFound";
import Header from "./components/header";

const App = () => {
  return (
    <Router>
      <main>
        <Header />

        <Switch>
          <Route path="/" component={HomeScreen} exact />
          <Route path="/order/:id" component={OrderScreen} />

          <Route path="*" component={NotFound} />
        </Switch>
      </main>
    </Router>
  );
};

export default App;

//export const serverLink = "http://localhost:5001";
export const serverLink = "https://api.top-exchange.pro";

export const telegramSupportLink="https://t.me/topexchange_support";
