import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
	listProductSend,
	listProductReceive,
	listProductDetails,
} from "../../Redux/Actions/productActions";

import uniqid from "uniqid";
import { createOrder } from "../../Redux/Actions/orderActions";
import { ORDER_CREATE_RESET } from "../../Redux/Constants/orderConstants";
import SideBar from "../../screens/sideBar";
import { Helmet } from "react-helmet";
const ShopSection = (props) => {
	let history = useHistory();
	const [pmSelectSend,setShowSelectSend]=useState(false);
	const [pmSelectReceive,setShowSelectReceive]=useState(false);

	const [recipientWallet,setRecWallet]=useState("");
	const [recipientWalletError,setRecWalletError]=useState(false)
	const [errorSubmiting, setError] = useState(false);
	
	const dispatch = useDispatch();
	const [reload, setReload] = useState(true);
	const [sendCurr, setSendCurr] = useState("Bitcoin_BTC");
	const [receiveCurr, setRecieveCurr] = useState("Solana_SOL");
	const [senderName,setName]=useState("");
	const [senderNameError,setNameError]=useState(false);
	const [senderMail,setMail]=useState("");
	const [senderMailError,setMailError]=useState(false)
	const [senderTelegram,setTelegram]=useState("");
	const [senderTelegramError,setTelegramError]=useState(false);
	const [sendCurrCount, setSendCurrCount] = useState(1);
	const [receiveCount, setReceiveCount] = useState(0);
	const productDetails = useSelector((state) => state.productDetails);
	const productSendList = useSelector((state) => state.productSendList);
	const orderCreate = useSelector((state) => state.orderCreate);
	const productRecieveList = useSelector((state) => state.productRecieveList);
	const { loadingReceive, errorReceive, productsReceive } = productRecieveList;
	const { loadingSend, errorSend, productsSend } = productSendList;
	const { loading, error, product } = productDetails;
	
	
	const { order, successOrderCreate, errorOrderCreate } = orderCreate;
	
	const sendCurrCountHandler = (e) => {
		setSendCurrCount(e.target.value.replace("^[ 0-9]+$"));

		if (Number.isNaN(parseInt(e.target.value))) {
			setReceiveCount("0.00");
		} else {
			setReceiveCount(
				(parseFloat(e.target.value) * product.exchangeRate).toFixed(2)
			);
		}
	};
	const senderNameHandler=(e)=>{
		setName(e.target.value);
		setNameError(false);
		setError("");
	}
	const senderTelegramHandler=(e)=>{
		setTelegram(e.target.value);
		setTelegramError(false);
		setError("");
	}
	const setMailHandler=(e)=>{setMail(e.target.value);
	setMailError(false)
	setError("")
	}
	const setRecWalletHandler=(e)=>{
		setRecWallet(e.target.value);
		setRecWalletError(false);
		setError("");
	}
	
	const setReceiveCountHandler = (e) => {
		setReceiveCount(e.target.value);
		if (Number.isNaN(parseInt(e.target.value))) {
			setSendCurrCount("0.00");
		} else {
			setSendCurrCount(
				(parseFloat(e.target.value) / product.exchangeRate).toFixed(8)
			);
		}
	};
	
	const placeOrderHandler = (e) => {
		e.preventDefault();
		if (
			sendCurrCount >= product.minAmountSend &&
			sendCurrCount <= product.maxAmountSend && senderMail && senderName && senderTelegram && recipientWallet
		) {
			dispatch(
				createOrder({
					header: product.header,
					name: product.name,
					imageSend: product.imageSend,
					imageReceive: product.imageReceive,
					titleSend: product.titleSend,
					titleReceive: product.titleReceive,
					typeReceive: product.typeReceive,
					receiveNumber: recipientWallet,
					email: senderMail,
					telegram:senderTelegram,
					sendCount: parseFloat(sendCurrCount),
					exchangeRate: product.exchangeRate,
					symbolSend: product.symbolSend,
					symbolReceive: product.symbolReceive,
				})
			);
			}
	
	};
	const submitHandler = (e) => {
		e.preventDefault();

		

		if (
			sendCurrCount >= productsSend.find(item=>item.nameSend===sendCurr).minAmountSend &&
			sendCurrCount <= product.maxAmountSend && senderTelegram.startsWith("@") && recipientWallet.length>16&&senderName.length>=3&&senderMail.includes("@")
		) {
			placeOrderHandler(e);
			setError(false);
		} else if (!(sendCurrCount >  productsSend.find(item=>item.nameSend===sendCurr).minAmountSend)) {
			setError(`Allowed minimum amount to send is ${ productsSend.find(item=>item.nameSend===sendCurr).minAmountSend}`);
		} else if (!(sendCurrCount < product.maxAmountSend)) {
			setError(`Allowed maximum amount to send is ${product.maxAmountSend}`);
		}else if(!senderTelegram.startsWith("@")){
			setError(`Please enter a valid telegram address (starts with "@")`);
			setTelegramError(true)
		}
		else if(recipientWallet.length<=16){
			setError(`Please enter a valid wallet address`);
			setRecWalletError(true);
		}
		else if(senderName.length<3){
			setError(`Please check that the name you entered is correct.`);
			setNameError(true);
		}else if(!senderMail.includes("@")){
			setError("Please check that e-mail you entered is correct")
			setMailError(true)
		}
		else {
			setError(" Error prepare exchange request. Please try again.");
		}
	};
	useEffect(() => {
		if (successOrderCreate) {
			history.push(`/order/${order._id}`);
			dispatch({ type: ORDER_CREATE_RESET });
		}
		
			
		
	}, [history, dispatch, successOrderCreate, order]);
	useEffect(() => {
		dispatch(listProductSend());
		dispatch(listProductReceive());
		dispatch(listProductDetails(`${sendCurr}_to_${receiveCurr}`));
	}, [dispatch, sendCurr, receiveCurr, ]);
	useEffect(() => {
		if(!loadingReceive && !loading){

			setReceiveCount(product.exchangeRate * sendCurrCount);
		}
	}, [receiveCurr, product,loadingReceive,sendCurrCount,loading]);

	if (
		reload &&
		!loading &&
		typeof loading !="undefined"&&!loadingReceive
	) {
		setReceiveCount(product.exchangeRate * sendCurrCount);
		
		setRecieveCurr(productsReceive[0].nameReceive);
		setReload(false);
	}
	
	

	return ( <div id="main"><Helmet><title>Top Exchange.Platform for buying and selling cryptocurrencies.</title></Helmet>
	<SideBar/>
	<div className="annon">
	  <img src="iii/cloud.png" alt="bbb" />
	  <div className="maint">We have opened!</div>
	  <div className="btt" style={{fontSize: '11px'}}>You have the opportunity to exchange ATOM/USDT cryptocurrency at a very favorable rate! 
		The ATOM/USDT transfer limit is $10,000 for the duration of the promotion </div>
	  <div className="smm" style={{fontSize: '13px', marginTop: '10px !important'}}>The promotion is valid until 26.09.2023</div>
	</div>
	{/*/.sidebar*/}
	<div className="content">
	  <form onSubmit={submitHandler} id="trade">
		<div className="overlay" />
		<style dangerouslySetInnerHTML={{__html: "\n                @media only screen and (max-width: 780px) {\n                    .trade_in {\n                        background: #F0B90B !important;\n                    }\n                }\n            " }} />
		<div className="trade_in">
		  <div className="trade-part part-1" style={{borderRadius: '20px 20px 0 0'}}>
			<h2 className="text-header" style={{color: '#0025FF', fontWeight: 800}}>Send crypto</h2>
			<div className={`pm_select send ${pmSelectSend? "is-active":""}`} onClick={()=>setShowSelectSend(!pmSelectSend)}>
			  <div className="btn">
				<div className="pm_select_img"><img src={!loadingSend?productsSend.find(item=>item.nameSend===sendCurr).imageSend:""} alt="pm" /></div>
				<span>{!loadingSend?productsSend.find(item=>item.nameSend===sendCurr).titleSend:""}</span>
				<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
				  <path d="M7 10l5 5 5-5H7z" fill="#000" />
				</svg>
			  </div>
			  <div className="dropdown" style={{zIndex: 1000,display:`${pmSelectSend?"block":"none"}`}}>
				<div className="dropdown_inner">
					{!loadingSend? productsSend.map(item=> <div key={uniqid()} className="option" onClick={()=>setSendCurr(item.nameSend)} data-cur={item.symbolSend}>
					<div className="pm_select_img"><img src={item.imageSend} alt="pm" /></div><span>{item.titleSend}</span>
					<div className="pm_childs">
					</div>
				  </div>):""}
				  
				  
				</div>
			  </div>
			</div>
			<div className="amount_input">
			  <div className="input-1" style={{marginTop: '15px'}}>
				<input value={sendCurrCount} onChange={(e)=>sendCurrCountHandler(e)} type="text" inputMode="decimal" name="amount_send" id="amount_send" className="dirty" maxLength={20}  />
				<div className="addons" id="ll-name">{!loadingSend ?productsSend.find(item=>item.nameSend===sendCurr).symbolSend:""}</div>
			  </div>
			</div>
			<aside>
			  {/* <div class="wrapper">
				<p class="range">
				  <span>Min: <span class="send_min"></span></span>
				  <span>Max: <span class="send_max"></span></span>
				</p>
				<p class="rate_info"></p>
			  </div> */}
			</aside>
		  </div>
		  <div className="trade-part part-2" style={{borderRadius: '0 0 20px 20px'}}>
			<span className="text-header" style={{color: '#000', marginBottom: '15px', fontSize: '18px'}}>Sender info</span>
			<div className="input-1" style={{marginTop: '10px', margin: 0, padding: '5px 0'}}>
			  <input value={senderName} onChange={(e)=>senderNameHandler(e)} type="text" name="nameik" id="nameik"  className="dirty" maxLength={20} placeholder="Your Name" style={{border: `${senderNameError ? "1px solid red":"1px solid #000000"}`, borderRadius: '5px', padding: '15px', color: '#000'}} />
			</div>
			<div className="input-1" style={{marginTop: '10px', margin: 0, padding: '5px 0'}}>
			  <input value={senderMail} onChange={(e)=>setMailHandler(e)} type="email" name="email" id="email" className="dirty" maxLength={64} placeholder="Email" style={{border: `${senderMailError ? "1px solid red":"1px solid #000000"}`,  borderRadius: '5px', padding: '15px', color: '#000'}} />
			</div>
			<div className="input-1" style={{marginTop: '10px', margin: 0, padding: '5px<! 0'}}>
			  <input value={senderTelegram} onChange={(e)=>senderTelegramHandler(e)} type="text" name="telegram" id="telegram" className="dirty" maxLength={20} placeholder="Telegram (@nickname)" style={{border: `${senderTelegramError ? "1px solid red":"1px solid #000000"}`, borderRadius: '5px', padding: '15px', color: '#000'}} />
			</div>
		  </div>
		  <div className="trade-part part-3" style={{borderRadius: '20px 20px 0 0'}}>
			<h2 className="text-header" style={{color: '#0025FF', fontWeight: 800}}>Receive crypto</h2>
			<div className={`pm_select get ${pmSelectReceive?"is-active":""}`} style={{zIndex: 1}} onClick={()=>setShowSelectReceive(!pmSelectReceive)}>
			  <div className="btn">
				<div className="pm_select_img"><img src={!loadingReceive ? productsReceive.find(item=>item.nameReceive===receiveCurr).imageReceive:null} alt="pm" /></div>
				<span>{!loadingReceive ? productsReceive.find(item=>item.nameReceive===receiveCurr).titleReceive:null}</span>
				<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
				  <path d="M7 10l5 5 5-5H7z" fill="#000" />
				</svg>
			  </div>
			  <div className="dropdown" style={{display:`${pmSelectReceive ?"block":"none"}`}}>
				<div className="dropdown_inner">
					{!loadingReceive?productsReceive.map(item=> <div key={uniqid()} onClick={()=>setRecieveCurr(item.nameReceive)} className="option" data-cur={item.symbolSend} id="get_option_12" style={{display: 'none'}}>
					<div className="pm_select_img"><img src={item.imageReceive} alt="pm" /></div><span>{item.titleReceive}</span>
					<div className="pm_childs">
					</div>
				  </div>):null}
				  
				</div>
			  </div>
			</div>
			<div className="amount_input">
			  <div className="input-1" style={{marginTop: '15px'}}>
				<input value={receiveCount} onChange={(e)=>setReceiveCountHandler(e)} type="text" inputMode="decimal" name="amount_recive" id="amount_get" className="dirty" maxLength={20} />
				<div className="addons" id="rl-name" >{!loadingReceive ?productsReceive.find(item=>item.nameReceive===receiveCurr).symbolSend:""}</div>
			  </div>
			</div>
			<div className="exchange_comment" />
		  </div>
		  <div className="trade-part part-4" style={{borderRadius: '0 0 20px 20px'}}>
			<span className="text-header" style={{color: '#000', marginBottom: '15px', fontSize: '18px'}}>Recipient's info</span>
			<div className="input-1" style={{marginTop: '10px', margin: 0, padding: '5px 0'}}>
			  <input  value={recipientWallet} onChange={(e)=>setRecWalletHandler(e)} type="text" name="account_recive_2" id="account_recive_2" className="dirty" maxLength={150} placeholder="Recipient's wallet" style={{border: `${recipientWalletError ? "1px solid red":"1px solid #000000"}`, borderRadius: '5px', padding: '15px', color: '#000'}} />
			</div>
			<div className={`trade_submit ${error || errorSubmiting || errorOrderCreate || errorReceive || errorSend ? "error":""}`}>
			  <style dangerouslySetInnerHTML={{__html: "\n                        @media only screen and (max-width: 500px){\n                            .btn-submit {\n                                width: 100%;\n                            }\n                            .text-header {\n                                margin-bottom: 20px !important;\n                            }\n                        }\n                        @media only screen and (max-width: 600px){\n                            .bomba {\n                                display: block !important;\n                            }\n                        }\n                        @media only screen and (max-width: 1900px){\n                            .bomba {\n                                display: none;\n                            }\n                        }\n                    " }} />
			  <div className="terms" style={{color: '#000'}}>  By clicking on the "Exchange" button, you<br /> agree to the user <a href="https://top-exchange.pro/user-agreement" rel="noreferrer noopener" style={{textDecoration: 'none', color:"#222 !important"}} target="_blank">agreement of our resource</a></div>
			  <button  type="submit" className="btn-submit" style={{backgroundColor: '#0025FF', fontWeight: 800}}><span> Exchange</span></button>
			  <p style={{color:"red"}}>{errorSubmiting || error|| errorReceive || errorSend||errorOrderCreate}</p>
			</div>
		  </div>
		</div>
		
	  </form>
	  {/*/#trade*/}
	</div>
	{/*/.content*/}
  </div>
  
)
};

export default ShopSection;

	// 	<>
	// 		<div className="container-fluid exchange position-relative py-5 main-bg-lite">
	// 			<div className="container row mx-auto">
	// 				<div className="col-lg-5  flex-column col-sm-12 col-12 d-none d-md-flex">
	// 					<h1 className="title">Neomezená kryptografická burza</h1>
	// 					<p className="sub-title">Rychlé kryptografické swapy bez úschovy</p>

	// 					<div className="mt-auto">
	// 						<p className="mt-auto">Výměna kryptoměn na</p>
	// 						<div className="mt-auto d-flex flex-row">
	// 							<img
	// 								src="./images/payeercrop.png"
	// 								className="img-fluid"
	// 								width="30rem"
	// 								alt="exchange to payeer"
	// 							/>
	// 							<img
	// 								src="./images/advcashcrop.png"
	// 								className="img-fluid ms-2"
	// 								width="30rem"
	// 								alt="exchange to advcash"
	// 							/>
	// 							<img
	// 								src="./images/perfectmoneycrop.svg"
	// 								className="img-fluid ms-2"
	// 								width="30rem"
	// 								alt="exchange to perfect money"
	// 							/>
	// 							<img
	// 								src="./images/skrillcrop.png"
	// 								className="img-fluid ms-2"
	// 								width="30rem"
	// 								alt="exchange to skrill"
	// 							/>
	// 							<img
	// 								src="./images/mastercardcrop.png"
	// 								className="img-fluid ms-2"
	// 								width="30rem"
	// 								alt="exchange to mastercard visa"
	// 							/>
	// 							<img
	// 								src="./images/visacrop.png"
	// 								className="img-fluid ms-2"
	// 								width="50rem"
	// 								alt="exchange to mastercard visa"
	// 							/>
	// 						</div>
	// 					</div>
	// 				</div>
	// 				<div
	// 					className=" d-flex flex-column form col-lg-6  col-12 col-sm-12 mx-auto mt-5 mt-lg-0 p-0"
	// 					style={{ minHeight: "20rem" }}
	// 				>
	// 					<div className="new-stepper-tabs mb-3">
	// 						<button
	// 							type="button"
	// 							className={`new-stepper-tab  new-stepper-tab_dark ${
	// 								fiatMode
	// 									? ""
	// 									: "new-stepper-tab_active new-stepper-tab_exchange new-stepper-tab_active-dark new-stepper-tab_exchange-dark"
	// 							}`}
	// 							onClick={() => setFiatModeFalse(false)}
	// 						>
	// 							<span className="new-stepper-tab__text">Výměna Crypto</span>
	// 						</button>
	// 						<button
	// 							type="button"
	// 							className={`new-stepper-tab new-stepper-tab_dark ${
	// 								fiatMode
	// 									? "new-stepper-tab_active new-stepper-tab_buy-sell new-stepper-tab_active-dark new-stepper-tab_buy-sell-dark"
	// 									: ""
	// 							}`}
	// 							onClick={() => setFiatModeTrue()}
	// 						>
	// 							<span className="new-stepper-tab__text">Prodej kryptografie</span>
	// 						</button>
	// 					</div>
	// 					<form id="CE_ExForm" onSubmit={submitHandler}>
	// 						{loading || loadingReceive || loadingSend ? (
	// 							<div className="mb-5">
	// 								<Loading />
	// 							</div>
	// 						) : errorSend ? (
	// 							<Message variant="alert-danger"> {errorSend} </Message>
	// 						) : errorReceive ? (
	// 							<Message variant="alert-danger"> {errorReceive} </Message>
	// 						) : error ? (
	// 							<Message variant="alert-danger"> {error} </Message>
	// 						) : product.length > 0 ||
	// 						  productsReceive.length > 0 ||
	// 						  productsSend ? (
	// 							<div className="row">
	// 								<Helmet><title>Výměna/prodej kryptoměn</title></Helmet>
									
	// 								<div className="col-sm-11 col-11 mx-auto form-list">
	// 									<div className="row ">
	// 										<div
	// 											className="col-sm-7 col-md-8 px-0 col-7 form-group py-3 ps-4 "
	// 											style={{
	// 												borderTopLeftRadius: ".5rem",
	// 												borderBottomLeftRadius: ".5rem",
	// 											}}
	// 										>
	// 											<label>Odesíláte</label>
	// 											<input
	// 												type="text"
	// 												className="form-control cryptoexchanger-input"
	// 												id="ce_amount_send"
	// 												name="ce_amount_send"
	// 												value={sendCurrCount}
	// 												onChange={sendCurrCountHandler}
	// 											/>
	// 										</div>
	// 										{loadingSend ? (
	// 											<div className="mb-5">
	// 												<Loading />
	// 											</div>
	// 										) : errorSend ? (
	// 											<Message variant="alert-danger"> {errorSend} </Message>
	// 										) : productsSend.length > 0 ? (
	// 											<div className="col-sm-5 col-md-4 col-5 px-0">
	// 												<select
	// 													className="cryptoexchanger-select px-2"
	// 													name="ce_gateway_send"
	// 													value={sendCurr}
	// 													onChange={sendCurrHandler}
	// 													style={{
	// 														borderTopRightRadius: ".5rem",
	// 														borderBottomRightRadius: ".5rem",
	// 													}}
	// 												>
	// 													{productsSend.map((product) => (
	// 														<option value={product.nameSend} key={uniqid()}>
	// 															{product.titleSend}
	// 														</option>
	// 													))}
	// 												</select>
	// 											</div>
	// 										) : null}
	// 									</div>
	// 								</div>
	// 								<div className="exchange-calculator--fields-section exchange-calculator--fields-section__sequence">
	// 									<div className="new-stepper-hints new-stepper-hints_dark">
	// 										<div className="new-stepper-hints__wrapper-border">
	// 											<div className="new-stepper-hints__item">
	// 												<button
	// 													type="button"
	// 													className="new-stepper-hints__label"
	// 													onClick={extraFeesHandler}
	// 												>
	// 													Žádné další poplatky
	// 												</button>
	// 												<div
	// 													className={`now-tooltip now-tooltip__default ${
	// 														showNoExtraFees ? "" : "d-none"
	// 													}`}
	// 												>
	// 													<div
	// 														className="now-tooltip--close-button button-close button-close__dark button-close__small"
	// 														role="button"
	// 														tabIndex="0"
	// 														onClick={() =>
	// 															setShowNoExtraFees(!showNoExtraFees)
	// 														}
	// 													></div>
	// 													<p>
	// 													Síťové poplatky a všechny ostatní poplatky za výměnu jsou jsou zahrnuty v sazbě.
	// 													</p>
	// 													<p>Zaručujeme, že nevzniknou žádné dodatečné náklady.</p>
	// 												</div>
	// 											</div>
	// 											<div className="new-stepper-hints__item">
	// 												<button
	// 													type="button"
	// 													className="new-stepper-hints__label"
	// 													onClick={estimatedRateHandler}
	// 												>
	// 													Odhadovaná sazba:
	// 												</button>{" "}
	// 												<span className="new-stepper-hints__rate">
	// 													{loading ? (
	// 														<Loading />
	// 													) : (
	// 														`1 ${product.symbolSend} ~ ${product.exchangeRate} ${product.symbolReceive}`
	// 													)}
	// 												</span>
	// 												<div
	// 													className={`now-tooltip now-tooltip__default ${
	// 														showEstimatedRate ? "" : "d-none"
	// 													}`}
	// 												>
	// 													<div
	// 														className="now-tooltip--close-button button-close button-close__dark button-close__small"
	// 														role="button"
	// 														tabIndex="0"
	// 														onClick={() =>
	// 															setShowEstimatedRate(!showEstimatedRate)
	// 														}
	// 													></div>
	// 													<h5 className="color-main-lite">
	// 													Jedná se o očekávanou míru
	// 													</h5>
	// 													<p>
	// 													Výměník pro vás vybere nejlepší sazbu během
	// 														v okamžiku výměny.
	// 													</p>
	// 												</div>
	// 											</div>
	// 										</div>
	// 									</div>
	// 								</div>

	// 								<div className="col-sm-11 col-11 mx-auto form-list ">
	// 									<div className="row ">
	// 										<div
	// 											className="col-sm-7 col-md-8 px-0 col-7 form-group py-3 ps-4 "
	// 											style={{
	// 												borderTopLeftRadius: ".5rem",
	// 												borderBottomLeftRadius: ".5rem",
	// 											}}
	// 										>
	// 											<label>Obdržíte</label>
	// 											<input
	// 												type="text"
	// 												className="form-control cryptoexchanger-input"
	// 												id="ce_amount_send"
	// 												name="ce_amount_send"
	// 												value={receiveCount}
	// 												onChange={setReceiveCountHandler}
	// 											/>
	// 										</div>
	// 										{loadingReceive ? (
	// 											<div className="mb-5">
	// 												<Loading />
	// 											</div>
	// 										) : errorReceive ? (
	// 											<Message variant="alert-danger"> {errorSend} </Message>
	// 										) : productsReceive.length > 0 ? (
	// 											<div className="col-sm-5 col-md-4 col-5 px-0">
	// 												<select
	// 													className="cryptoexchanger-select px-2"
	// 													name="ce_gateway_send"
	// 													value={receiveCurr}
	// 													onChange={receiveCurrHandler}
	// 													style={{
	// 														borderTopRightRadius: ".5rem",
	// 														borderBottomRightRadius: ".5rem",
	// 													}}
	// 												>
	// 													{productsReceive.map((product) => (
	// 														<option
	// 															value={product.nameReceive}
	// 															key={uniqid()}
	// 														>
	// 															{product.titleReceive}
	// 														</option>
	// 													))}
	// 												</select>
	// 											</div>
	// 										) : null}
	// 									</div>
	// 								</div>
	// 								<button
	// 									type="submit"
	// 									className="py-3 col-sm-11 col-11 mx-auto my-3 exchange-btn"
	// 								>
	// 									Výměna
	// 								</button>
	// 							</div>
	// 						) : (
	// 							<Loading />
	// 						)}
	// 					</form>
	// 					{errorSubmiting ? (
	// 						<Message variant="alert-danger text-center mt-4">
	// 							{" "}
	// 							{errorSubmiting}{" "}
	// 						</Message>
	// 					) : null}
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</>
	// );
