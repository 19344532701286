import React from "react";
import { useEffect, useState } from "react";
import { serverLink } from "../../App";
import uniqid from "uniqid";
const LastTransactions = () => {
  const [lastTrans, setTrans] = useState([]);
  useEffect(() => {
    fetch(`${serverLink}/api/lastorders`).then(res => res.json()).then(json => setTrans(json));
  }, [])
 


  return (<section id="last_transactions" data-num_show={30}>
    <div className="text-page" style={{ borderRadius: '20px' }}>
      <div className="last_trans__header">
        <h2 className="text-header" style={{ color: '#0025FF' }}>Latest transactions</h2>
      </div>
      <div className="last_trans__list">
        <div className="container-2">
          <div className="table-lt table-lt-header">
            <div className="tr">
              <div className="td" style={{ color: '#000' }}>Time</div>
              <div className="td" style={{ color: '#000' }}>Operation</div>
              <div className="td" style={{ color: '#000' }}>Quantity</div>
            </div>
          </div>
          <div className="table-lt table-lt-body">
            {lastTrans ? lastTrans.map(item => <div key={uniqid()} className="tr">
              <div className="td"><label>Время</label><span className="date">{item.time}</span></div>
              <div className="td">
                <div className="exch"><span className="nw"><img src={item.imageSend} className="coin protip" data-pt-title="Stellar" alt="" /></span><span className="icon-arrow-2"><img src="iii/obmen.svg" alt="obm" /></span><span className="nw"><img src={item.imageReceive} className="coin protip" data-pt-title="Dash" alt="" /></span>
                </div>
              </div>
              <div className="td"><label>Количество</label>{item.amount}</div>
            </div>) : null}
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default LastTransactions;